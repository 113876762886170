import { useEffect } from 'react';
import { navigate } from 'gatsby';
import { routes } from '../shared/global';

export default () => {
  useEffect(() => {
    navigate(routes.home);
  }, []);
  return null;
};
